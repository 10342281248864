.sec_title {
  position: relative;
  text-align: center;
  font-size: 4.5rem;
  font-family: $ff-en;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.2em;
  padding: 30px 0;
  margin-bottom: 20px;
  @include mq {
    font-size: 3rem;
  }

  &_ja {
    display: block;
    font-family: $ff-serif;
    font-size: 2.6rem;
    font-style: normal;
    color: $font-color-point;
    @include mq {
      font-size: 1.75rem;
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    width: 135px;
    height: 135px;
    background: url(../img/heading_logo_01.svg);
    background-size: contain;

    @include mq {
      width: 100px;
      height: 100px;
    }
  }
}

.sec_sub_title {
  text-align: center;
  font-size: calc(1.2vw + 20px);
  font-weight: normal;
  margin-bottom: 40px;
}
