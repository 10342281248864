
// Size
//===========================================================

$width-container: 1100px;
$width-viewport-lg: $width-container+100px;
$width-gutter: 15px;

//Media Query
//------------------------
$breakpoint-lg: $width-viewport-lg;
$breakpoint-sm: 769px;


//color
//==========================================================

//カラー定義
//--------------------------------
$white: #fff;
$black: #000;
$gray: #a1a5ad;
$gray_light: #e3e3e4;
$gray-dark: #686e78;
$gray-darkest: #505763;


//theme-color
//--------------------------------
$theme-color: #b39b5a;
$theme-color-light: #bfaa72;

//font-color
//--------------------------------
$font-color: $black;
$font-color-light: $white;
$font-color-sub: $gray;
$font-color-point: $theme-color;

//border-color
//---------------------------------
$border-color-default: $gray_light;
$border-color-dark: #33363b;


// Font
//============================================================


//font-family
//--------------------------------
$ff-serif: "游明朝","Yu Mincho","游明朝体","YuMincho","ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","HiraMinProN-W3","HGS明朝E","ＭＳ Ｐ明朝","MS PMincho",serif;
$ff-sans-serif: "游ゴシック体","Yu Gothic",'YakuHanJP','Noto Sans Japanese',YuGothic"Hiragino Kaku Gothic ProN",Meiryo,sans-serif;
$ff-tsuold-D: TsukuAOldMinPr6-D,serif;
$ff-tsuold-B: TsukuAOldMinPr6-B,serif;
$ff-en: minion-pro-caption,serif;
$ff-futura: futura, sans-serif;
$ff-default: $ff-serif;

//font-setting
//-------------------------------
$line-height-default : 1.75;




