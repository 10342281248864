@mixin clearfix($selector: 'after') {
  &::#{$selector} {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin hover {
  .no-touchevents &:hover {
    @content;
  }
}

@mixin fade-hover($value: .7) {
  transition: .4s;
  cursor: pointer;

  &:hover {
    opacity: $value;
  }
}

// Gradation
@mixin grad($start, $end) {
  background: $start;
  background: linear-gradient($start, $end);
}

// Container
@mixin container($width: $width-container) {
  padding-left: calc(50% - #{$width} / 2);
  padding-right: calc(50% - #{$width} / 2);

  @include mq(md) {
    padding-left: ($width-viewport-lg - $width) / 2;
    padding-right: ($width-viewport-lg - $width) / 2;
  }

  @include mq(sm) {
    padding-left: $width-gutter;
    padding-right: $width-gutter;
  }
}

// Column
@mixin col($column-count,
$gutter: $width-gutter,
$inverse: false,
$selector: '> *'

) {
  @include clearfix;
  $direction: if($inverse, right, left);

  #{$selector} {
    // 余白を一旦リセット
    margin-left: 0;
    margin-top: 0;
    float: $direction;

    @if $gutter !=0 {
      $margins: $gutter * ($column-count - 1);

      width: calc((100% - #{$margins}) / #{$column-count});

      // 左右の余白
      + *:not(:nth-child(#{$column-count}n+1)) {
        margin-#{$direction}: #{$gutter};
      }

      // 上下の余白
      &:not(:nth-child(-n+#{$column-count})) {
        margin-top: #{$gutter};
      }
    }

    @else {
      width: calc(100% / #{$column-count});
    }
  }
}

// 色を明るくする
@function tint($color, $percent) {
  @if not _is-color($color) {
    @error '`#{$color}` is not a valid color for the `$color` argument in the `tint` mixin.';
  }

  @else {
    @return mix(#fff, $color, $percent);
  }
}

// 色を暗くする
@function shade($color, $percent) {
  @if not _is-color($color) {
    @error '`#{$color}` is not a valid color for the `$color` argument in the `shade` mixin.';
  }

  @else {
    @return mix(#000, $color, $percent);
  }
}

// Media query
@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}

@mixin mq($screen: sm) {

  // モバイル
  @if $screen==sm {
    @include max-screen($breakpoint-sm - 1) {
      @content;
    }
  }

  // モバイルより大きくパソコン版のコンテナより小さい
  @else if $screen==md {
    @include screen($breakpoint-sm, $width-viewport-lg) {
      @content;
    }
  }

  // パソコン・タブレット版
  @else if $screen==lg {
    @include min-screen($breakpoint-sm) {
      @content;
    }
  }

  //fontsize設定用
  @else if $screen==fz {
    @include min-screen($width-viewport-lg) {
      @content;
    }
  }

  @else {
    @include max-screen($screen) {
      @content;
    }
  }
}


//fontsizeレスポンシブ


