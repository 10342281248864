@charset "UTF-8";
@import url(ress/ress.css);
html {
  font-size: 10px;
  font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-feature-settings: "palt";
  color: #000;
  letter-spacing: .1em;
  line-height: 1.7; }

* {
  box-sizing: border-box; }

a {
  color: #000;
  text-decoration: none;
  width: inherit;
  height: inherit; }

li {
  list-style: none; }

img {
  max-width: 100%; }

.page_content {
  padding: 80px 0 120px; }
  @media screen and (max-width: 768px) {
    .page_content {
      padding: 30px 0 60px; } }

body {
  min-width: 1200px; }
  @media screen and (max-width: 768px) {
    body {
      min-width: 380px; } }
  body .sp_none {
    display: block; }
    @media screen and (max-width: 768px) {
      body .sp_none {
        display: none; } }
  body .pc_none {
    display: none; }
    @media screen and (max-width: 768px) {
      body .pc_none {
        display: block; } }

.hidden {
  visibility: hidden; }

.page_content {
  position: relative; }
  .page_content .page_link {
    position: absolute;
    top: -80px; }

@media screen and (min-width: 769px) {
  .gnav__toggle {
    display: none; } }

@media (max-width: 768px) {
  .pc {
    display: none; }
  .container {
    padding-left: 15px;
    padding-right: 15px; }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: #fff; }
    .header::after {
      clear: both;
      content: "";
      display: block; }
  header .gnav {
    position: fixed;
    flex-direction: column;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 80px);
    background-color: #fff;
    border-top: 1px solid #e3e3e4;
    overflow-y: auto;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s ease-out; }
    header .gnav_items {
      display: block; }
    header .gnav_item {
      height: 90px; }
      header .gnav_item_link {
        display: flex;
        align-items: center;
        padding-left: 5%;
        text-align: left;
        font-size: 3rem; }
        header .gnav_item_link_ja {
          display: inline;
          padding-left: 10px;
          font-size: 1.5rem;
          vertical-align: center; }
    header .gnav_contact .gnav_item_link {
      height: 90px;
      background: #fff;
      color: #000;
      padding-left: 5%;
      padding-right: 0; }
  [data-gnav='show'] .gnav {
    z-index: 9;
    visibility: visible;
    opacity: 1; }
  .gnav__item {
    position: relative;
    display: block;
    padding: 0.5em 15px;
    border-bottom: 1px solid #e3e3e4; }
    .gnav__item::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 20px;
      margin: auto;
      transition: all .2s ease-out; }
  .gnav__toggle {
    position: relative;
    flex-shrink: 0;
    margin-left: 10px;
    width: 80px;
    height: 80px;
    border-left: 1px solid #e3e3e4;
    float: right; }
    .gnav__toggle span {
      position: absolute;
      left: 32%;
      display: inline-block;
      margin: auto;
      transition: all .2s ease-out;
      box-sizing: border-box;
      width: 36%;
      height: 3px;
      background-color: #b39b5a;
      border-radius: 4px; }
      .gnav__toggle span:nth-of-type(1) {
        top: 36%; }
      .gnav__toggle span:nth-of-type(2) {
        top: 0;
        bottom: 0; }
      .gnav__toggle span:nth-of-type(3) {
        bottom: 36%; }
      [data-gnav='show'] .gnav__toggle span:nth-of-type(1) {
        -webkit-transform: translateY(9.5px) rotate(-45deg);
        transform: translateY(9.5px) rotate(-45deg); }
      [data-gnav='show'] .gnav__toggle span:nth-of-type(2) {
        opacity: 0; }
      [data-gnav='show'] .gnav__toggle span:nth-of-type(3) {
        -webkit-transform: translateY(-9.5px) rotate(45deg);
        transform: translateY(-9.5px) rotate(45deg); } }

.sec_title {
  position: relative;
  text-align: center;
  font-size: 4.5rem;
  font-family: minion-pro-caption, serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.2em;
  padding: 30px 0;
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    .sec_title {
      font-size: 3rem; } }
  .sec_title_ja {
    display: block;
    font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    font-size: 2.6rem;
    font-style: normal;
    color: #b39b5a; }
    @media screen and (max-width: 768px) {
      .sec_title_ja {
        font-size: 1.75rem; } }
  .sec_title::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    width: 135px;
    height: 135px;
    background: url(../img/heading_logo_01.svg);
    background-size: contain; }
    @media screen and (max-width: 768px) {
      .sec_title::before {
        width: 100px;
        height: 100px; } }

.sec_sub_title {
  text-align: center;
  font-size: calc(1.2vw + 20px);
  font-weight: normal;
  margin-bottom: 40px; }

body {
  overflow-x: hidden; }

header {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: #fff;
  padding: 0 0 0 2%; }
  header .page_title {
    position: relative;
    width: 430px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 2%;
    padding-top: 5px;
    transition: .4s;
    cursor: pointer; }
    header .page_title:hover {
      opacity: 0.7; }
    @media screen and (max-width: 768px) {
      header .page_title {
        margin-right: 10%; } }
    header .page_title_link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block; }
    header .page_title img {
      display: block;
      max-height: 40px; }
      @media screen and (max-width: 768px) {
        header .page_title img {
          width: 200px; } }
    header .page_title span {
      display: block;
      font-size: calc(0.45vw + 9px);
      font-weight: normal;
      letter-spacing: 0;
      padding: 1em 0 0.3em 1em; }
      @media screen and (min-width: 1200px) {
        header .page_title span {
          font-size: 1.4rem; } }
      @media screen and (max-width: 484px) {
        header .page_title span {
          margin-top: -2em;
          padding: 0 0 0.3em 2.5em; } }
  header .gnav {
    display: flex; }
    header .gnav_items {
      display: flex; }
    header .gnav_item {
      position: relative;
      height: 100%;
      border-left: 1px solid #e3e3e4; }
      header .gnav_item::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: #b39b5a;
        transform: scale(0);
        transition: .4s; }
      header .gnav_item:hover::before {
        transform: scale(1); }
      header .gnav_item_link {
        display: block;
        text-align: center;
        font-family: minion-pro-caption, serif;
        font-size: calc(0.5vw + 12px);
        letter-spacing: 0.1em;
        padding: 20px;
        line-height: 1.5; }
        @media screen and (min-width: 1200px) {
          header .gnav_item_link {
            font-size: 1.8rem; } }
        header .gnav_item_link_ja {
          display: block;
          font-family: "游ゴシック体", "Yu Gothic", "YakuHanJP", "Noto Sans Japanese", YuGothic "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
          font-size: 1.2rem; }
    header .gnav_contact {
      height: 100%; }
      header .gnav_contact .gnav_item_link {
        background-color: #b39b5a;
        color: #fff;
        padding: 20px 4em;
        transition: .4s;
        cursor: pointer; }
        header .gnav_contact .gnav_item_link:hover {
          opacity: 0.8; }

.hero {
  position: relative;
  padding-left: calc(50% - 1100px / 2);
  padding-right: calc(50% - 1100px / 2);
  background: url(../img/hero.jpg) no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
  height: 0;
  padding-top: 42%;
  margin-top: 80px; }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .hero {
      padding-left: 50px;
      padding-right: 50px; } }
  @media screen and (max-width: 768px) {
    .hero {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 768px) {
    .hero {
      padding-top: 127%;
      background: url(../img/hero_sp.jpg) no-repeat;
      background-size: contain; } }
  .hero_lead {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 768px) {
      .hero_lead {
        top: 30%; } }
    .hero_lead_logo {
      max-width: 260px;
      margin: 0 auto;
      margin-bottom: 40px; }
      @media screen and (max-width: 768px) {
        .hero_lead_logo {
          max-width: 135px;
          margin-bottom: 15px; } }
    .hero_lead .catch {
      font-family: TsukuAOldMinPr6-D, serif;
      letter-spacing: .2em;
      font-size: calc(0.9vw + 14px); }
      @media screen and (max-width: 768px) {
        .hero_lead .catch {
          letter-spacing: 0;
          margin-bottom: 0.5em; } }
      .hero_lead .catch:last-child {
        letter-spacing: .05em;
        font-size: calc(1.5vw + 23px); }

.message {
  padding-left: calc(50% - 1100px / 2);
  padding-right: calc(50% - 1100px / 2);
  padding-top: 30px;
  background: url(../img/bg_01.jpg) center;
  background-size: cover; }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .message {
      padding-left: 50px;
      padding-right: 50px; } }
  @media screen and (max-width: 768px) {
    .message {
      padding-left: 15px;
      padding-right: 15px; } }
  .message_inner_text {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    font-size: 2rem;
    letter-spacing: 0.15em;
    margin-bottom: 2em; }
    @media screen and (max-width: 768px) {
      .message_inner_text {
        max-width: 500px; } }

.service {
  padding-left: calc(50% - 1100px / 2);
  padding-right: calc(50% - 1100px / 2);
  background: url(../img/bg_02.jpg) 30% top;
  background-size: cover; }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .service {
      padding-left: 50px;
      padding-right: 50px; } }
  @media screen and (max-width: 768px) {
    .service {
      padding-left: 15px;
      padding-right: 15px; } }
  .service_title {
    color: #fff; }
    .service_title::before {
      background: url(../img/heading_logo.svg); }

.service_01 {
  margin-bottom: 100px; }
  .service_01::after {
    clear: both;
    content: "";
    display: block; }
  @media screen and (max-width: 768px) {
    .service_01 {
      margin-bottom: 50px; } }
  .service_01_title {
    color: #fff; }
  .service_01_subtitle {
    text-align: center;
    font-family: "游ゴシック体", "Yu Gothic", "YakuHanJP", "Noto Sans Japanese", YuGothic "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
    font-size: 1.8rem;
    color: #fff;
    padding: 0 3rem;
    margin-bottom: 90px; }
    @media screen and (max-width: 768px) {
      .service_01_subtitle {
        text-align: justify;
        padding: 0 1em;
        margin-bottom: 45px; } }
  .service_01_chart {
    width: calc(50% - 40px);
    text-align: center; }
    @media screen and (max-width: 768px) {
      .service_01_chart {
        width: 90%;
        max-width: 500px; } }
    .service_01_chart_left {
      position: relative;
      float: left; }
      @media screen and (max-width: 768px) {
        .service_01_chart_left {
          float: none;
          margin: 0 auto 65px; } }
      .service_01_chart_left::after {
        position: absolute;
        top: 50%;
        right: -55px;
        transform: translateY(-50%);
        content: '';
        display: block;
        width: 30px;
        height: 100px;
        border-left: 30px solid #fff;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent; }
        @media screen and (max-width: 768px) {
          .service_01_chart_left::after {
            top: 104%;
            right: 50%;
            transform: translateX(50%);
            width: 125px;
            height: 25px;
            border-left: 62.5px solid transparent;
            border-top: 25px solid #fff;
            border-right: 62.5px solid transparent; } }
    .service_01_chart_right {
      float: right; }
      @media screen and (max-width: 768px) {
        .service_01_chart_right {
          float: none;
          margin: 0 auto; } }
    .service_01_chart_title {
      position: relative;
      background-color: #fff;
      padding: 30px 0;
      font-family: TsukuAOldMinPr6-D, serif;
      font-size: 2.6rem; }
      .service_01_chart_title_en {
        display: block;
        font-family: minion-pro-caption, serif;
        font-size: 1.6rem;
        letter-spacing: 0.2em; }
      .service_01_chart_title::after {
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 34px;
        height: 15px;
        border-top: 15px solid #fff;
        border-right: 17px solid transparent;
        border-left: 17px solid transparent; }
    .service_01_chart_list_item {
      padding: 25px .5em;
      font-size: 2rem;
      font-family: "游ゴシック体", "Yu Gothic", "YakuHanJP", "Noto Sans Japanese", YuGothic "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
      font-weight: bold;
      color: #fff;
      list-style: none; }
      @media screen and (max-width: 768px) {
        .service_01_chart_list_item {
          font-size: 1.6rem; } }

.list_problem {
  background-color: #505763; }
  .list_problem:nth-child(even) {
    background-color: #686e78; }

.list_solution {
  background-color: #b39b5a; }
  .list_solution:nth-child(even) {
    background-color: #bfaa72; }

.service_02::after {
  clear: both;
  content: "";
  display: block; }

.service_02_title {
  display: flex;
  align-items: center;
  font-family: TsukuAOldMinPr6-B, serif;
  font-size: calc(0.5vw + 25px);
  color: #fff;
  letter-spacing: 0.2em;
  margin-bottom: 55px; }
  @media screen and (max-width: 768px) {
    .service_02_title {
      letter-spacing: 0.1em; } }
  .service_02_title::before, .service_02_title::after {
    content: '';
    display: block;
    flex: 1;
    border-top: 1px solid #e3e3e4; }
  .service_02_title::before {
    margin-right: 30px; }
    @media screen and (max-width: 768px) {
      .service_02_title::before {
        margin-right: 15px; } }
  .service_02_title::after {
    margin-left: 30px; }
    @media screen and (max-width: 768px) {
      .service_02_title::after {
        margin-left: 15px; } }

.service_02_card {
  position: relative;
  width: 352px;
  float: left; }
  @media screen and (max-width: 768px) {
    .service_02_card {
      float: none;
      margin: 0 auto; } }
  .service_02_card:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      .service_02_card:not(:last-child) {
        margin-right: auto; } }
  .service_02_card .card-inner {
    position: absolute;
    top: 40%;
    padding: 0 25px; }
  .service_02_card_imgwrap {
    width: 100%; }
  .service_02_card_num {
    font-size: 4.5rem;
    font-family: minion-pro-caption, serif;
    font-style: italic;
    line-height: 1.4; }
  .service_02_card_title {
    font-size: 2.5rem;
    font-weight: normal;
    letter-spacing: 0.2em;
    margin-bottom: 20px; }
    .service_02_card_title .title_en {
      display: block;
      font-family: minion-pro-caption, serif;
      font-size: 1.5rem;
      color: #b39b5a;
      letter-spacing: .1em; }
  .service_02_card_text {
    font-family: "游ゴシック体", "Yu Gothic", "YakuHanJP", "Noto Sans Japanese", YuGothic "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
    font-size: 1.6rem;
    text-align: justify; }

.letter-s_0 {
  letter-spacing: -1px; }

@media all and (-ms-high-contrast: none) {
  .letter-s_0 {
    letter-spacing: -4px; } }

.company {
  padding-left: calc(50% - 1100px / 2);
  padding-right: calc(50% - 1100px / 2);
  background: url(../img/bg_03.jpg) center;
  background-size: cover; }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .company {
      padding-left: 50px;
      padding-right: 50px; } }
  @media screen and (max-width: 768px) {
    .company {
      padding-left: 15px;
      padding-right: 15px; } }
  .company_table {
    display: flex;
    flex-wrap: wrap;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    font-family: "游ゴシック体", "Yu Gothic", "YakuHanJP", "Noto Sans Japanese", YuGothic "Hiragino Kaku Gothic ProN", Meiryo, sans-serif; }
    @media screen and (max-width: 768px) {
      .company_table {
        max-width: 600px; } }
    .company_table dt,
    .company_table dd {
      border-bottom: 1px solid #e3e3e4;
      padding: 30px 25px;
      font-size: 1.6rem; }
      @media screen and (max-width: 768px) {
        .company_table dt,
        .company_table dd {
          padding: 15px 30px;
          width: 100%; } }
    .company_table_title {
      text-align: center;
      width: 160px;
      background: #b39b5a;
      font-weight: bold;
      color: #fff; }
      @media screen and (max-width: 768px) {
        .company_table_title {
          text-align: left;
          width: 100%; } }
    .company_table_data {
      width: calc(100% - 160px);
      background-color: #fff; }
      @media screen and (max-width: 768px) {
        .company_table_data {
          width: 100%; } }
      .company_table_data .add_title {
        font-size: 1.6rem; }
        .company_table_data .add_title:last-of-type {
          margin-top: 1.5em; }
      .company_table_data_list {
        counter-reset: num; }
        .company_table_data_list > li {
          position: relative;
          padding-left: 3.5rem; }
          .company_table_data_list > li::before {
            position: absolute;
            left: 0;
            content: counter(num) ". ";
            counter-increment: num;
            display: inline-block; }

.contact {
  padding-left: calc(50% - 1100px / 2);
  padding-right: calc(50% - 1100px / 2);
  padding-top: 60px;
  padding-bottom: 55px;
  background: url(../img/bg_04.jpg) center;
  background-size: cover;
  color: #fff;
  font-family: "游ゴシック体", "Yu Gothic", "YakuHanJP", "Noto Sans Japanese", YuGothic "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  text-align: center; }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .contact {
      padding-left: 50px;
      padding-right: 50px; } }
  @media screen and (max-width: 768px) {
    .contact {
      padding-left: 15px;
      padding-right: 15px; } }
  .contact::after {
    clear: both;
    content: "";
    display: block; }
  .contact_lead {
    font-size: calc(0.8vw + 22px);
    font-family: TsukuAOldMinPr6-D, serif;
    letter-spacing: 0.15em;
    margin-bottom: 50px; }
    @media screen and (max-width: 768px) {
      .contact_lead {
        margin-bottom: 20px; } }
  .contact_sec {
    padding: 30px 20px;
    width: calc(50% - 1px); }
    @media screen and (max-width: 768px) {
      .contact_sec {
        width: 100%;
        max-width: 400px;
        margin: 0 auto; } }
  .contact_title {
    font-size: 2.6em; }
    @media screen and (max-width: 768px) {
      .contact_title {
        font-size: 2rem; } }
  .contact_tel {
    float: left;
    border-right: 1px solid #e3e3e4; }
    @media screen and (max-width: 768px) {
      .contact_tel {
        float: none;
        border-right: none;
        border-bottom: 1px solid #e3e3e4; } }
    .contact_tel_num {
      font-family: TsukuAOldMinPr6-B, serif;
      font-size: calc(1.2vw + 30px);
      color: #b39b5a;
      letter-spacing: 0.05em;
      line-height: 1.4; }
      .contact_tel_num a {
        font-family: TsukuAOldMinPr6-B, serif;
        font-size: calc(1.2vw + 30px);
        color: #b39b5a;
        letter-spacing: 0.05em;
        line-height: 1.4; }
    .contact_tel_sub {
      font-size: calc(0.28vw + 11px);
      letter-spacing: 0.05em; }
      @media screen and (max-width: 768px) {
        .contact_tel_sub {
          font-size: 1.3rem; } }
  .contact_mail {
    float: right; }
    @media screen and (max-width: 768px) {
      .contact_mail {
        float: none; } }
    .contact_mail_link {
      display: block;
      width: 100%;
      max-width: 400px;
      background-color: #b39b5a;
      margin: 30px auto 0;
      font-size: 1.8rem;
      font-weight: bold;
      color: #fff;
      padding: 1em; }
      @media screen and (max-width: 768px) {
        .contact_mail_link {
          margin: 10px auto; } }

.back_to_top {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background-color: #505763; }
  .back_to_top::after {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    content: '';
    display: block;
    width: 23px;
    height: 23px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff; }

.footer_inner {
  display: flex;
  padding-left: calc(50% - 1100px / 2);
  padding-right: calc(50% - 1100px / 2);
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #14171d;
  font-family: "游ゴシック体", "Yu Gothic", "YakuHanJP", "Noto Sans Japanese", YuGothic "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  color: #fff; }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .footer_inner {
      padding-left: 50px;
      padding-right: 50px; } }
  @media screen and (max-width: 768px) {
    .footer_inner {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 768px) {
    .footer_inner {
      display: none; } }

.footer_info {
  margin-right: 60px;
  flex-shrink: 0; }
  .footer_info_logo {
    margin-bottom: 25px;
    transition: .4s;
    cursor: pointer; }
    .footer_info_logo:hover {
      opacity: 0.7; }
  .footer_info_tel {
    font-family: TsukuAOldMinPr6-B, serif;
    font-size: 2.5rem; }
    .footer_info_tel span {
      background-color: #b39b5a;
      border-radius: 3.5px;
      display: inline-block;
      font-size: 1.5rem;
      color: #000;
      padding: 0 3px 0 5px;
      margin-right: 8px; }

.footer_address {
  margin-right: 42px; }
  .footer_address_title {
    font-size: 1.6rem;
    color: #b39b5a;
    margin-bottom: 0.3em; }
    .footer_address_title:not(:first-of-type) {
      margin-top: 2.5em; }
  .footer_address_data {
    font-size: 1.5rem; }

.footer_link {
  width: 200px;
  padding: 0 20px;
  border-left: 1px solid #33363b; }
  .footer_link_list {
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.1em; }
    .footer_link_list a {
      display: block;
      color: #fff;
      margin-bottom: 40px;
      transition: .4s;
      cursor: pointer; }
      .footer_link_list a:hover {
        opacity: 0.7; }
  .footer_link_sub a {
    display: block;
    font-size: 1.4rem;
    color: #a1a5ad; }

.footer small {
  display: block;
  background-color: #000;
  text-align: center;
  color: #fff;
  padding: 0.5em;
  font-family: "游ゴシック体", "Yu Gothic", "YakuHanJP", "Noto Sans Japanese", YuGothic "Hiragino Kaku Gothic ProN", Meiryo, sans-serif; }
