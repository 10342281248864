body {
  overflow-x: hidden;
}

header {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: $white;
  padding: 0 0 0 2%;

  .page_title {
    position: relative;
    width: 430px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 2%;
    padding-top: 5px;
    @include fade-hover;

    @include mq {
      margin-right: 10%;
    }

    &_link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
    }

    img {
      display: block;
      max-height: 40px;

      @include mq {
        width: 200px;
      }
    }

    span {
      display: block;
      font-size: calc(0.45vw + 9px);
      font-weight: normal;
      letter-spacing: 0;
      padding: 1em 0 0.3em 1em;

      @include mq(fz) {
        font-size: 1.4rem;
      }

      @include mq(484px) {
        margin-top: -2em;
        padding: 0 0 0.3em 2.5em;
      }
    }
  }

  .gnav {
    display: flex;

    &_items {
      display: flex;
    }

    &_item {
      position: relative;
      height: 100%;
      border-left: 1px solid $border-color-default;
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: $theme-color;
        transform: scale(0);
        transition: .4s;
      }
      &:hover {
        &::before{
          transform: scale(1);
        }
      }

      &_link {
        display: block;
        text-align: center;
        font-family: $ff-en;
        font-size: calc(0.5vw + 12px);
        letter-spacing: 0.1em;
        padding: 20px;
        line-height: 1.5;

        @include mq(fz) {
          font-size: 1.8rem;
        }

        &_ja {
          display: block;
          font-family: $ff-sans-serif;
          font-size: 1.2rem;
        }
      }
    }

    &_contact {
      height: 100%;

      .gnav_item_link {
        background-color: $theme-color;
        color: $font-color-light;
        padding: 20px 4em;
        @include fade-hover(.8);
      }
    }
  }
}

// ヒーロー
// =======================================

.hero {
  position: relative;
  @include container;
  background: url(../img/hero.jpg) no-repeat;
  background-size: cover;
  text-align: center;
  color: $white;
  height: 0;
  padding-top: 42%;
  margin-top: 80px;

  @include mq {
    padding-top: 127%;
    background: url(../img/hero_sp.jpg) no-repeat;
    background-size: contain;
  }

  &_lead {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mq {
      top: 30%;
    }

    &_logo {
      max-width: 260px;
      margin: 0 auto;
      margin-bottom: 40px;

      @include mq {
        max-width: 135px;
        margin-bottom: 15px;
      }
    }

    .catch {
      font-family: $ff-tsuold-D;
      letter-spacing: .2em;
      font-size: calc(0.9vw + 14px);

      @include mq {
        letter-spacing: 0;
        margin-bottom: 0.5em;
      }

      &:last-child {
        letter-spacing: .05em;
        font-size: calc(1.5vw + 23px);
      }
    }
  }
}

// メッセージ
// =======================================


.message {
  @include container;
  padding-top: 30px;
  background: url(../img/bg_01.jpg) center;
  background-size: cover;

  &_inner_text {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    font-size: 2rem;
    letter-spacing: 0.15em;
    margin-bottom: 2em;

    @include mq {
      max-width: 500px;
    }
  }
}

// 事業内容
// =======================================


.service {
  @include container;
  background: url(../img/bg_02.jpg) 30% top;
  background-size: cover;

  &_title {
    color: $white;

    &::before {
      background: url(../img/heading_logo.svg);
    }
  }
}

//問題解決
//----------------------------
.service_01 {
  @include clearfix;
  margin-bottom: 100px;

  @include mq {
    margin-bottom: 50px;
  }

  &_title {
    color: $white;
  }

  &_subtitle {
    text-align: center;
    font-family: $ff-sans-serif;
    font-size: 1.8rem;
    color: $white;
    padding: 0 3rem;
    margin-bottom: 90px;

    @include mq {
      text-align: justify;
      padding: 0 1em;
      margin-bottom: 45px;
    }
  }

  &_chart {
    width: calc(50% - 40px);
    text-align: center;

    @include mq {
      width: 90%;
      max-width: 500px;
    }

    &_left {
      position: relative;
      float: left;

      @include mq {
        float: none;
        margin: 0 auto 65px;
      }

      &::after {
        position: absolute;
        top: 50%;
        right: -55px;
        transform: translateY(-50%);
        content: '';
        display: block;
        width: 30px;
        height: 100px;
        border-left: 30px solid $white;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;

        @include mq {
          top: 104%;
          right: 50%;
          transform: translateX(50%);
          width: 125px;
          height: 25px;
          border-left: 62.5px solid transparent;
          border-top: 25px solid $white;
          border-right: 62.5px solid transparent;
        }
      }
    }

    &_right {
      float: right;

      @include mq {
        float: none;
        margin: 0 auto;
      }
    }

    &_title {
      position: relative;
      background-color: $white;
      padding: 30px 0;
      font-family: $ff-tsuold-D;
      font-size: 2.6rem;

      &_en {
        display: block;
        font-family: $ff-en;
        font-size: 1.6rem;
        letter-spacing: 0.2em;
      }

      &::after {
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 34px;
        height: 15px;
        border-top: 15px solid $white;
        border-right: 17px solid transparent;
        border-left: 17px solid transparent;
      }
    }

    &_list {
      &_item {
        padding: 25px .5em;
        font-size: 2rem;
        font-family: $ff-sans-serif;
        font-weight: bold;
        color: $white;
        list-style: none;

        @include mq {
          font-size: 1.6rem;
        }
      }
    }
  }
}

//list-color
.list_problem {
  background-color: $gray-darkest;

  &:nth-child(even) {
    background-color: $gray-dark;
  }
}

.list_solution {
  background-color: $theme-color;

  &:nth-child(even) {
    background-color: $theme-color-light;
  }
}

//依頼の流れ
//----------------------------
.service_02 {
  @include clearfix;

  &_title {
    display: flex;
    align-items: center;
    font-family: $ff-tsuold-B;
    font-size: calc(0.5vw + 25px);
    color: $white;
    letter-spacing: 0.2em;
    margin-bottom: 55px;

    @include mq {
      letter-spacing: 0.1em;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      flex: 1;
      border-top: 1px solid $border-color-default;
    }

    &::before {
      margin-right: 30px;

      @include mq {
        margin-right: 15px;
      }
    }

    &::after {
      margin-left: 30px;

      @include mq {
        margin-left: 15px;
      }
    }

  }

  &_card {
    position: relative;
    width: 352px;
    float: left;

    @include mq {
      float: none;
      margin: 0 auto;
    }

    &:not(:last-child) {
      margin-right: 20px;
      margin-bottom: 20px;

      @include mq {
        margin-right: auto;
      }
    }

    .card-inner {
      position: absolute;
      top: 40%;
      padding: 0 25px;
    }

    &_imgwrap {
      width: 100%;
    }

    &_num {
      font-size: 4.5rem;
      font-family: $ff-en;
      font-style: italic;
      line-height: 1.4;
    }

    &_title {
      font-size: 2.5rem;
      font-weight: normal;
      letter-spacing: 0.2em;
      margin-bottom: 20px;

      .title_en {
        display: block;
        font-family: $ff-en;
        font-size: 1.5rem;
        color: $theme-color;
        letter-spacing: .1em;
      }
    }

    &_text {
      font-family: $ff-sans-serif;
      font-size: 1.6rem;
      text-align: justify;
    }
  }
}

.letter-s_0 {
  letter-spacing: -1px;
}

@media all and (-ms-high-contrast: none) {
  .letter-s_0 {
    letter-spacing: -4px;
  }
}

// 会社概要
// =======================================
.company {
  @include container;
  background: url(../img/bg_03.jpg) center;
  background-size: cover;

  &_table {
    display: flex;
    flex-wrap: wrap;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    font-family: $ff-sans-serif;

    @include mq {
      max-width: 600px;
    }

    dt,
    dd {
      border-bottom: 1px solid $border-color-default;
      padding: 30px 25px;
      font-size: 1.6rem;

      @include mq {
        padding: 15px 30px;
        width: 100%;
      }
    }

    &_title {
      text-align: center;
      width: 160px;
      background: $theme-color;
      font-weight: bold;
      color: $white;

      @include mq {
        text-align: left;
        width: 100%;
      }
    }

    &_data {
      width: calc(100% - 160px);
      background-color: $white;

      @include mq {
        width: 100%;
      }

      .add_title {
        font-size: 1.6rem;

        &:last-of-type {
          margin-top: 1.5em
        }

      }

      &_list {
        counter-reset: num;

        >li {
          position: relative;
          padding-left: 3.5rem;

          &::before {
            position: absolute;
            left: 0;
            content: counter(num) ". ";
            counter-increment: num;
            display: inline-block;
          }
        }
      }
    }
  }
}

// コンタクト
// =======================================
.contact {
  @include container;
  padding-top: 60px;
  padding-bottom: 55px;
  @include clearfix;
  background: url(../img/bg_04.jpg) center;
  background-size: cover;
  color: $white;
  font-family: $ff-sans-serif;
  text-align: center;

  &_lead {
    font-size: calc(0.8vw + 22px);
    font-family: $ff-tsuold-D;
    letter-spacing: 0.15em;
    margin-bottom: 50px;

    @include mq {
      margin-bottom: 20px;
    }
  }

  &_sec {
    padding: 30px 20px;
    width: calc(50% - 1px);

    @include mq {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
  }

  &_title {
    font-size: 2.6em;

    @include mq {
      font-size: 2rem;
    }
  }

  &_tel {
    float: left;
    border-right: 1px solid $border-color-default;

    @include mq {
      float: none;
      border-right: none;
      border-bottom: 1px solid $border-color-default;
    }

    &_num {
      font-family: $ff-tsuold-B;
      font-size: calc(1.2vw + 30px);
      color: $font-color-point;
      letter-spacing: 0.05em;
      line-height: 1.4;

      a {
        font-family: $ff-tsuold-B;
        font-size: calc(1.2vw + 30px);
        color: $font-color-point;
        letter-spacing: 0.05em;
        line-height: 1.4;
      }
    }

    &_sub {
      font-size: calc(0.28vw + 11px);
      letter-spacing: 0.05em;

      @include mq {
        font-size: 1.3rem;
      }
    }
  }

  &_mail {
    float: right;

    @include mq {
      float: none;
    }

    &_link {
      display: block;
      width: 100%;
      max-width: 400px;
      background-color: $theme-color;
      margin: 30px auto 0;
      font-size: 1.8rem;
      font-weight: bold;
      color: $white;
      padding: 1em;

      @include mq {
        margin: 10px auto;
      }
    }
  }
}

// footer
// =======================================

.back_to_top {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background-color: $gray-darkest;

  &::after {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    content: '';
    display: block;
    width: 23px;
    height: 23px;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
  }
}

.footer {
  &_inner {
    display: flex;
    @include container;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #14171d;
    font-family: $ff-sans-serif;
    color: $white;

    @include mq {
      display: none;
    }
  }

  &_info {
    margin-right: 60px;
    flex-shrink: 0;

    &_logo {
      margin-bottom: 25px;
      @include fade-hover;
    }

    &_tel {
      font-family: $ff-tsuold-B;
      font-size: 2.5rem;

      span {
        background-color: $theme-color;
        border-radius: 3.5px;
        display: inline-block;
        font-size: 1.5rem;
        color: $black;
        padding: 0 3px 0 5px;
        margin-right: 8px;
      }
    }
  }

  &_address {
    margin-right: 42px;

    &_title {
      font-size: 1.6rem;
      color: $theme-color;
      margin-bottom: 0.3em;

      &:not(:first-of-type) {
        margin-top: 2.5em;
      }
    }

    &_data {
      font-size: 1.5rem;
    }
  }

  &_link {
    width: 200px;
    padding: 0 20px;
    border-left: 1px solid $border-color-dark;

    &_list {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.1em;

      a {
        display: block;
        color: $white;
        margin-bottom: 40px;
        @include fade-hover;
      }
    }

    &_sub {
      a {
        display: block;
        font-size: 1.4rem;
        color: $gray;
      }
    }
  }

  small {
    display: block;
    background-color: #000;
    text-align: center;
    color: $white;
    padding: 0.5em;
    font-family: $ff-sans-serif;
  }
}
