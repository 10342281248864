html {
  font-size: 10px;
  font-family: $ff-default;
  font-feature-settings: "palt";
  color: $font-color;
  letter-spacing: .1em;
  line-height: 1.7;
}

* {
  box-sizing: border-box;
}

a {
  color: $font-color;
  text-decoration: none;
  width: inherit;
  height: inherit;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
}

.page_content {
  padding: 80px 0 120px;
  @include mq {
    padding: 30px 0 60px;
  }
}

body {
  min-width: 1200px;
  @include mq {
  min-width: 380px;
  }

  .sp_none {
    display: block;

    @include mq {
      display: none;
    }
  }

  .pc_none {
    display: none;

    @include mq {
      display: block;
    }
  }
}

.hidden {
  visibility: hidden;
}


.page_content{
  position: relative;
  .page_link {
    position: absolute;
    top: -80px;
  }
}


