

.gnav__toggle {
  @include mq(lg) {
    display: none;
  }
}

$height-gnav: 80px;

@media (max-width: 768px) {

  .pc {
    display: none;
  }

  .container {
    padding-left: $width-gutter;
    padding-right: $width-gutter;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: $white;
    @include clearfix;
  }

  header {
    .gnav {
      position: fixed;
      flex-direction: column;
      top: $height-gnav;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: calc(100vh - 80px);
      background-color: $white;
      border-top: 1px solid $border-color-default;
      overflow-y: auto;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
      transition: opacity .2s ease-out;

      &_items {
        display: block;
      }

      &_item {
        height: 90px;

        &_link {
          display: flex;
          align-items: center;
          padding-left: 5%;
          text-align: left;
          font-size: 3rem;

          &_ja {
            display: inline;
            padding-left: 10px;
            font-size: 1.5rem;
            vertical-align: center;
          }
        }
      }

      &_contact {
        .gnav_item_link {
          height: 90px;
          background: $white;
          color: $font-color;
          padding-left: 5%;
          padding-right: 0;
        }
      }
    }
  }

  .gnav {
    [data-gnav='show'] & {
      z-index: 9;
      visibility: visible;
      opacity: 1;
    }

    &__item {
      position: relative;
      display: block;
      padding: .5em $width-gutter;
      border-bottom: 1px solid $border-color-default;

      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        margin: auto;
        transition: all .2s ease-out;
      }
    }

    &__toggle {
      position: relative;
      flex-shrink: 0;
      margin-left: 10px;
      width: $height-gnav;
      height: $height-gnav;
      border-left: 1px solid $border-color-default;
      float: right;

      //ハンバーガーアイコン
      span {
        position: absolute;
        left: 32%;
        display: inline-block;
        margin: auto;
        transition: all .2s ease-out;
        box-sizing: border-box;
        width: 36%;
        height: 3px;
        background-color: $theme-color;
        border-radius: 4px;

        &:nth-of-type(1) {
          top: 36%;
        }

        &:nth-of-type(2) {
          top: 0;
          bottom: 0;
        }

        &:nth-of-type(3) {
          bottom: 36%;
        }

        //ハンバーガーアイコン×ボタン
        [data-gnav='show'] & {
          &:nth-of-type(1) {
            -webkit-transform: translateY(9.5px) rotate(-45deg);
            transform: translateY(9.5px) rotate(-45deg);
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            -webkit-transform: translateY(-9.5px) rotate(45deg);
            transform: translateY(-9.5px) rotate(45deg);
          }
        }
      }
    }
  }
}
